import React, { useEffect, useState } from "react";
import SkipLink from "../skip-link/skip-link";
import { getHeader } from "../../services/page-elements";
import "./site-nav.scss";

export default function SiteNav() {
  const [header, setHeader] = useState();

  useEffect(() => {
    fetchHeader().catch((err) => setHeader(""));
  });

  async function fetchHeader() {
    if (!hasHeader()) {
      const data = await getHeader();
      setHeader(data);
    } else {
      console.log("loading skipped");
    }
  }

  function hasHeader() {
    return document.getElementsByClassName("navbar-global-header").length > 0;
  }

  if (!header) {
    return <div className="site-header-skeleton">No Header</div>;
  }

  return (
    <>
      <SkipLink />
      <div dangerouslySetInnerHTML={{ __html: header }} />
    </>
  );
}
