import axios from "axios";
import { enterpriseApiKey } from "../util/constants";

const basePath = "/enterprise";
function buildPath(path: string) {
  if (
    window.location.hostname.includes("localhost.staging") ||
    window.location.hostname.includes("pilot")
  ) {
    return `https://api.pilot.principal.com/enterprise/theme/member/v3/header?apikey=DMEQy9nPXTlao5VhwbTF0OGN70O8aMgf`;
  }

  return `${basePath}${path}`;
}

export async function get(path: string, paramsOverrides = {}) {
  const { data } = await axios.get(buildPath(path), {
    params: { key: enterpriseApiKey, ...paramsOverrides },
  });

  return data;
}
